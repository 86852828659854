<template>
    <nav class="flex MontserratMedium" @mouseover="mouseOver" @mouseleave="showMenu = ''">
        <div class="nav-section-1" />

        <div class="nav-section-2" />

        <div class="nav-section-3 flex relative z-10">
            <div class="block absolute w-screen h-1 flex right-0" style="top: 40px; z-index: -1">
                <span class="bg-black" style="width: 5vw" />
                <span style="width: 55vw" />
                <span class="bg-teilor" style="width: 40vw" />
            </div>

            <div class="nav-link-1 pb-2 w-1/3 flex flex-col">
                <div ref="te-ma">
                    <!--                        <span ref="teilor-link" class="relative">TEILOR</span>-->
                    <router-link id="teilor-link" class="main-link" :to="{ name:'teilor', hash:'#AboutUs', params: { language } }">TEILOR</router-link>
                    <ul v-if="showMenu == 'teilor'" class="pt-5">
                        <li><router-link :to="{ name:'teilor', hash:'#AboutUs', params: { language } }">ABOUT US</router-link></li>
                        <li><router-link :to="{ name:'teilor', hash:'#History', params: { language } }">HISTORY</router-link></li>
                        <li><router-link :to="{ name:'teilor', hash:'#MapOfStores', params: { language } }">MAP OF STORES</router-link></li>
                        <li><router-link :to="{ name:'teilor', hash:'#OnlineStores', params: { language } }">ONLINE STORES LINKS</router-link></li>
                    </ul>
                </div>
            </div>

            <div class="nav-link-2 pb-2 w-1/3 flex flex-col">
                <div ref="mg-ma">
                    <router-link id="moneygold-link" class="main-link" :to="{ name:'mg', hash:'#AboutMoneyGold', params: { language } }">MONEYGOLD</router-link>
                    <ul v-if="showMenu == 'moneygold'" class="pt-5">
                        <li><router-link :to="{ name:'mg', hash:'#AboutMoneyGold', params: { language } }">ABOUT US</router-link></li>
                        <li><router-link :to="{ name:'mg', hash:'#MGHistory', params: { language } }">HISTORY</router-link></li>
                        <li><router-link :to="{ name:'mg', hash:'#MGMapOfStores', params: { language } }">MAP OF STORES</router-link></li>
                        <li><router-link :to="{ name:'mg', hash:'#Values', params: { language } }">OUR VALUES</router-link></li>
                    </ul>
                </div>
            </div>

            <div class="nav-link-3 pb-2 w-1/3 flex flex-col">
                <div ref="investors">
                    <router-link id="investors-link" class="main-link" :to="{ name:'inv', hash:'#obligatiuni', params: { language } }">{{ language == 'RO' ? 'INVESTITORI' : 'INVESTORS' }}</router-link>
                    <ul v-if="showMenu == 'investors'" class="pt-5">
                        <!-- <li><router-link :to="{ name:'inv', hash:'#obligatiuni', params: { language } }">{{ language == 'RO' ? 'OBLIGATIUNI TEILOR HOLDING' : 'TEILOR HOLDING BONDS' }}</router-link></li>
                        <li><router-link :to="{ name:'inv', hash:'#calendar_financiar', params: { language } }">{{ language == 'RO' ? 'CALENDAR FINANCIAR' : 'FINANCIAL CALENDAR' }}</router-link></li>
                        <li><router-link :to="{ name:'inv', hash:'#rezultate_financiare', params: { language } }">{{ language == 'RO' ? 'REZULTATE FINANCIARE' : 'FINANCIAL RESULTS' }}</router-link></li>
                        <li><router-link :to="{ name:'inv', hash:'#rapoarte_curente', params: { language } }">{{ language == 'RO' ? 'RAPOARTE CURENTE' : 'CURRENT REPORTS' }}</router-link></li>
                        <li><router-link :to="{ name:'inv', hash:'#guvernanta_corporativa', params: { language } }">{{ language == 'RO' ? 'GUVERNANTA CORPORATIVA' : 'CORPORATE GOVERNANCE' }}</router-link></li>
                        <li><router-link :to="{ name:'inv', hash:'#ir_contact', params: { language } }">{{ language == 'RO' ? 'IR CONTACT' : 'CONTACT IR' }}</router-link></li> -->
                        <li><a target="_blank" href="/TeilorHolding.pdf">> TEILOR Holding</a></li>
                        <li><a target="_blank" href="/2021AnnualReport.pdf">> 2021 ANNUAL REPORT</a></li>
                        <li><a target="_blank" href="/RaportTeilorHoldingS1-2022.pdf">> 2022-S1 REPORT</a></li>
                        <li><a target="_blank" href="/Raport Anual 2022 VF_small.pdf">> 2022 ANNUAL REPORT</a></li>
                        <li><a target="_blank" href="/Teilor-Holding-2022 Sustainability-Report.pdf">> 2022 SUSTAINABILITY REPORT</a></li>
                        <li><a target="_blank" href="/TEI S1 2023 RO.pdf">> 2023-S1 REPORT</a></li>
                        <li><a target="_blank" href="/Teilor Holding 2023 Annual Report Final_small.pdf">> 2023 ANNUAL REPORT</a></li>
                    </ul>
                </div>
<!--                <div ref="in-ma" :style="{ marginLeft: linkMargin + 'px' }">-->
<!--                    <a href="/TeilorHolding.pdf" target="_blank" id="investors-link" class="main-link">INVESTORS</a>-->
<!--                </div>-->
            </div>
        </div>
    </nav>
</template>

<script>
    import {ref} from "vue";
    import {mapGetters} from "vuex";

    export default {
        setup () {
            const showMenu = ref('');

            // const hoverMenu = (mouse, menu) => {
            //     if (mouse == 'exit') showMenu.value = '';
            //     else showMenu.value = menu;
            // }

            const mouseOver = el => {
                el = el.target;
                let target = '';
                while (el.parentNode) {
                    if (el.classList.contains('nav-link-1') || el.classList.contains('teilor')) target = 'teilor';
                    else if (el.classList.contains('nav-link-2') || el.classList.contains('moneygold')) target = 'moneygold';
                    if (el.classList.contains('nav-link-3') || el.classList.contains('investors')) target = 'investors';
                    // if (el.classList.contains('teilor-nav') || el.classList.contains('teilor')) target = 'teilor';
                    // else if (el.classList.contains('mg-nav') || el.classList.contains('moneygold')) target = 'moneygold';
                    // if (el.classList.contains('investors-nav') || el.classList.contains('investors')) target = 'investors';
                    el = el.parentNode;
                }
                showMenu.value = target;
            }


            return {
                showMenu,
                // hoverMenu,
                mouseOver
            }
        },

        data: () => ({
            linkMargin: '0'
        }),

        computed: {
            ...mapGetters(['language'])
        },

        mounted () {
            const teilorLinkWidth = document.querySelector('#teilor-link').offsetWidth;
            const mgLinkWidth = document.querySelector('#moneygold-link').offsetWidth;
            const invLinkWidth = document.querySelector('#investors-link').offsetWidth;
            // const teilorLinkWidth = this.$refs["teilor-link"].offsetWidth;
            // const mgLinkWidth = this.$refs["moneygold-link"].offsetWidth;
            // const invLinkWidth = this.$refs["investors-link"].offsetWidth;
            const max = Math.max(teilorLinkWidth, mgLinkWidth, invLinkWidth);
            const teilorContainerWidth = this.$refs["te-ma"].offsetWidth;
            this.linkMargin = (teilorContainerWidth - max) / 2 + '';
        }
    }
</script>

<style scoped lang="scss">
    nav {
        @apply absolute right-0;
        top: 25%;
        width: 100vw;
    }


    ul {
        li {
            @apply py-2;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-bottom-color: transparent;
            cursor: pointer;
            &:hover {
                @apply border-teilor;
            }
        }
        &:first-child {
            @apply mt-0;
        }
        &:last-child {
            @apply mb-0;
        }
    }

    .investors-nav, .teilor-nav, .mg-nav {
        border-bottom-style: solid;
        border-bottom-width: 4px;
        @apply border-teilor;
        @apply pb-2;
    }

    .nav-link-1, .nav-link-2, .nav-link-3 {
        .main-link {
            cursor: pointer;
            display: inline-block;
            padding: 2px 6px;
            border-style: solid;
            border-width: 1px;
            border-color: transparent;
        }
        &:hover {
            .main-link {
                @apply border-teilor;
            }
        }
    }
    .nav-section-1 {
        width: 5%;
    }
    .nav-section-2 {
        width: 55%;
    }
    .nav-section-3 {
        width: 40%;
    }
</style>
