import { createRouter, createWebHistory } from 'vue-router';
import Teilor from './components/Teilor';
import MoneyGold from "./components/MoneyGold";
import Investors from "./components/Investors";
// import smoothscroll from 'smoothscroll-polyfill';
const smoothscroll = require("smoothscroll-polyfill");

const routes = [
    { path: '/:language/Teilor', component: Teilor, name: 'teilor' },
    { path: '/:language/Moneygold', component: MoneyGold, name: 'mg' },
    { path: '/:language/Investitori', component: Investors, name: 'inv' }
]

const scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        setTimeout(() => {
            smoothscroll.polyfill();
            const element = document.getElementById(to.hash.replace(/#/, ''))
            if (element && element.scrollIntoView) {
                var headerOffset = 52;
                var elementPosition = element.getBoundingClientRect().top;
                var offsetPosition = elementPosition - headerOffset;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
                // element.scrollIntoView({behavior: 'smooth', block: "center"})
            }
        }, 100)
        // return {selector: to.hash}
    }
    else if (savedPosition) {
        return savedPosition
    }
    return {top: 0}
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior
})


export default router
